<template>
  <div class="add-key">
    <gc-dialog
      title="新增密钥"
      class="account-manage"
      :show.sync="visible"
      :ok-text="step > 0 ? '提交' : '阅读完毕，开始录入'"
      @ok="handleOkBtnClick"
      @cancel="visible = false"
    >
      <div class="step step0" v-if="step === 0">
        <div class="sub-title">注意事项</div>
        <div class="content">
          一、输入规则：<br />
          1.单组密钥为16位；<br />
          2.密钥内容为16字节，例如：F0DE-2834-2C9E-6A0B。<br />
          二、录入规则：<br />
          密钥分四次一次录入，录入完毕后，生成完整密钥。
        </div>
      </div>
      <div class="step" v-else>
        <div class="sub-title">第{{ charForStep[step - 1] }}组密钥输入</div>
        <div class="tip">
          请使用如下16进制字节组合：<br />1、2、3、4、5、6、7、8、9、0、A、B、C、D、E、F
        </div>
        <el-form :model="modelForm" :rules="modelRules" ref="modelForm">
          <div class="el-form-item-group">
            <div class="label">密钥：</div>
            <el-form-item
              v-for="(item, idx) in 4"
              :key="'input' + idx"
              :prop="`keyInput.${idx}`"
            >
              <el-input
                :ref="'keyInput' + idx"
                v-model="modelForm.keyInput[idx]"
                @keyup.enter.native.stop="handleKeyEnter('keyInput', idx)"
              />
            </el-form-item>
          </div>
          <div class="el-form-item-group">
            <div class="label">确认密钥：</div>
            <el-form-item
              v-for="(item, idx) in 4"
              :key="'confirm' + idx"
              :prop="`keyConfirm.${idx}`"
            >
              <el-input
                :ref="'keyConfirm' + idx"
                v-model="modelForm.keyConfirm[idx]"
                @keyup.enter.native.stop="handleKeyEnter('keyConfirm', idx)"
              />
            </el-form-item>
          </div>
          <div class="el-form-item-group">
            <div class="label">厂商：</div>
            <el-form-item prop="company">
              <el-select v-model="modelForm.company" placeholder="请选择厂商">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>

        <div class="form-input confirm"></div>
      </div>
    </gc-dialog>
  </div>
</template>

<script>
export default {
  name: "AddKey",
  components: {},
  props: {},
  data() {
    return {
      visible: false,
      step: 0,
      charForStep: ["一", "二", "三", "四"],
      modelForm: {
        keyInput: {},
        keyConfirm: {},
      },
      modelRules: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    _initForm() {
      const inputValidator = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入密钥"));
        } else {
          const reg = new RegExp(/[0-9A-F]{4}/);
          if (!reg.exec(value)) {
            callback(new Error("错误的密钥"));
          }
        }
        const { field } = rule;
        // eslint-disable-next-line no-unused-vars
        const [element, idx] = field.split(".");
        const { keyInput, keyConfirm } = this.modelForm;
        if (keyConfirm[idx]) {
          this.$refs.modelForm.validateField([`keyConfirm.${idx}`]);
        }

        callback();
      };
      const confirmValidator = (rule, value, callback) => {
        const reg = new RegExp(/[0-9A-F]{4}/);
        if (value === "") {
          callback(new Error("请输入密钥"));
        } else if (!reg.exec(value)) {
          callback(new Error("错误的密钥"));
        } else {
          const { field } = rule;
          // eslint-disable-next-line no-unused-vars
          const [element, idx] = field.split(".");
          const { keyInput, keyConfirm } = this.modelForm;
          if (keyInput[idx] !== keyConfirm[idx]) {
            callback(new Error("上下密钥不同"));
          }
        }
        callback();
      };
      const keyInput = {},
        keyConfirm = {};
      for (let i = 0; i < 4; i++) {
        keyInput[i] = "";
        keyConfirm[i] = "";
        this.$set(this.modelRules, "keyInput." + i, {
          validator: inputValidator,
          trigger: "change",
        });
        this.$set(this.modelRules, "keyConfirm." + i, {
          validator: confirmValidator,
          trigger: "change",
        });
        this.$set(this.modelRules, "company", {
          required: true,
          message: "请选择厂商",
        });
      }
      this.$set(this.modelForm, "keyInput", keyInput);
      this.$set(this.modelForm, "keyConfirm", keyConfirm);
    },
    show() {
      this.visible = true;
      this.step = 1;
      this._initForm();
    },
    handleKeyEnter(prefix, idx) {
      if (idx < 3) {
        const refName = prefix + (idx + 1);
        this.$refs[refName][0].focus();
      }
    },
    handleOkBtnClick() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.step++;
          this.$refs.modelForm.resetFields();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form {
    margin-top: 20px;
  }
  .el-form-item {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }
}
.el-form-item-group {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 20px;
  .label {
    width: 70px;
    font-size: 14px;
    color: #222222;
    margin-right: 10px;
  }
}
.add-key {
  .step {
    .sub-title {
      font-size: 14px;
      font-weight: 600;
      color: #222222;
      line-height: 21px;
      margin-bottom: 14px;
    }
    .tip {
      font-size: 12px;
      color: #666666;
      line-height: 18px;
    }
  }
  .step0 {
    .content {
      padding: 6px 0 16px 20px;
      width: 100%;
      height: 142px;
      background: #ebeefa;
      border-radius: 4px;
      font-size: 14px;
      color: #6b7399;
      line-height: 20px;
      box-sizing: border-box;
    }
  }
}
</style>
